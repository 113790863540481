import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import { createI18n } from "vue-i18n";
import messages from "./i18n";

import moment from "moment";

/* 
Vue.filter("moment", function (value, format) {
  if (
    value === null ||
    value === undefined ||
    value == "" ||
    format === undefined
  ) {
    return "";
  }
  if (format === "from") {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
}); */

router.beforeEach((to, from, next) => {
  store.commit("setFromRoute", to.path);
  const loginPath = `/login`;
  if (to.matched.some((record) => record.meta.requireAdmin)) {
    //console.log("AA");
    if (
      !(store.getters.isAdmin && store.getters.loggedIn) &&
      to.path !== loginPath
    ) {
      next({
        path: loginPath,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requireAuth)) {
    //console.log("BB", to.path);
    if (!store.getters.loggedIn && to.path !== loginPath) {
      //console.log("to", to);
      next({
        path: loginPath,
        query: { redirect: to.path ? to.path : from.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requireSuperAdmin)) {
    //console.log("CC");
    /* console.log(
          store.getters.loggedIn,
          store.getters.account,
          store.getters.isAdmin
      ); */
    if (
      !(
        store.getters.loggedIn &&
        store.getters.account == "arceau" &&
        store.getters.isAdmin &&
        to.path !== loginPath
      )
    ) {
      next({
        path: loginPath,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

let locale = localStorage.getItem("language");
if(!locale) {
  locale = "fr";
  localStorage.setItem("language", "fr");
}
const i18n = createI18n({
  legacy: false,
  locale: "fr",
  fallbackLocale: "fr",
  messages,
});
import(`quasar/lang/${locale}`).then(({ default: messages }) => {
  Quasar.lang.set(messages);
});
moment.locale(locale.slice(0, 2));

const app = createApp(App);
app.config.globalProperties.$moment = moment;

app
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(i18n)
  .mount("#app");
