export default {
  dashboard: "tableau de bord",
  month: "mois",
  active: "Actif",
  inactive: "Inactif",
  emptyList: "Cette liste est vide",
  true: "Oui",
  false: "Non",
  notFound: "Non trouvé",
  title: {
    editProfile: "Modifier mon profil",
    confirmDelete: "Confirmez la suppression",
    devInProgress: "Cette page est en cours de développement",
    users: "Users",
    editUser: "Edit user",
    addUser: "Add new user",
    scripts: "Scripts",
    executions: "Executions",
  },
  button: {
    add: "Ajouter",
    dashboard: "Tableau de bord",
    cancel: "annuler",
    save: "enregistrer",
    signIn: "connexion",
    logOut: "Déconnexion",
    myProfile: "Mon profil",
    config: "Configuration",
    users: "Utilisateurs",
    settings: "Paramètres",
    ok: "OK",
  },
  menu: {
    reports: "Rapports",
    refill: "Rechargement",
    changes: "Zippotage",
    system: "Système",
    users: "Utilisateurs",
    scripts: "Scripts"
  },
  message: {
    notFound404: "Cette page n'existe pas, ou est en cours de développement!",
    confirmDeleteUser:
      "Voulez-vous vraiment supprimer définitivement cet utilisateur ?",
  },
  hint: {
    number: "Nombre",
    required: "Requis",
    minchar: "Min {chars} caractères",
    emailFormat: "Au format e-mail",
    password: "Mot de passe",
    repeatPassword: "Identique à mot de passe",
    requireLogin:
      "Il vous sera demandé de vous reconnecter avec les nouvelles informations afin de valider les changements.",
    numberLength: "Nombre à {chars} chiffres",
    newCodeCommentRequired: "Le commentaire est requis",
  },
  label: {
    password: "Mot de passe",
    email: "E-mail",
    repeatPassword: "Confirmez le mot de passe",
    active: "Actif",
    roles: "Rôles",
    description: "Description",
    role: {
      admin: "Administrateur",
      super: "Super Admin",
      user: "Utilisateur",
    },
    name: "Nom",
    cron: "Cron",
    lastSuccessExecutionDate: "Dernier succes",
    lastExecutionDate: "Dernière execution",
    lastExecutionResult: "Etat",
    date: "Date",
    duration: "Durée",
    result: "Résultat",
  },
  validation: {
    verify: "Veuillez corriger le formulaire",
    required: "Ce champ ne doit pas être vide",
    minchar: "Un minimum de {chars} caractères est requis",
    repeatPassword: "Les mots de passe doivent être identiques",
    isEmail: "Adresse e-mail non valable",
    number: "Ce champ doit être un nombre",
    positive: "Ce champs doit contenir un nombre positif",
    numberlength: "Ce champ doit être un nombre à {chars} chiffres",
  },
  tooltip: {
    editUser: "Modifier l'utilisateur",
    addUser: "Ajouter un utilisateur",
    deleteUser: "Supprimer définitivement l'utilisateur",
    execute: "Executer",
    viewScript: "Voir",
    viewLog: "Logs",
  },
  action: {
    new: "Création",
    complete: "Finalisation",
  },

  api: {
    user: {
      profileUpdated: "Profil modifié",
      updated: "Utilisateur modifié",
      created: "User created",
      removed: "User removed",
    },
    login: { failure: "E-mail ou mot de passe invalide" },
  },
};
