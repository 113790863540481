<template>
  <div class="flex full-width reverse-wrap no-wrap justify-between bg-white">

  </div>
  <q-card flat>


    <q-separator class="q-ma-sm" />

    <q-card-section
      class="row text-green-vito justify-around wrap no-padding-md"
    >
      <stats-by-network class="col-md-6 col-12" />
      <stats-by-product class="col-md-6 col-12" />
    </q-card-section>
  </q-card>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  components: {  },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      stats: { deployedStores: 0, displayAlerts: [] },
      statsLoaded: false,
      refreshCounter: 0,
    };
  },
  mounted() {
    this.$store.state.title = "button.dashboard";
    this.locale = localStorage.getItem("language");
  },
  methods: {
    alertsPayload() {
      return {
        openVisits: this.stats.visitAlerts,
        lowBatteryDisplays: this.stats.displayAlerts.batteryAlerts,
        outOfDateDisplays: this.stats.displayAlerts.outOfDateAlerts,
        pendingContracts: this.stats.pendingContracts,
        storeTimeout: this.stats.displayAlerts.storeTimeout,
      };
    },
    async refresh() {
      console.log("refreshing");
      this.refreshCounter += 1;
      await this.getStats();
    },
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>
