<template>
  <q-card
    bordered
    class="q-mt-md q-pa-sm shadow-1"
    style="width: 450px; max-width: 90vw"
  >
    <q-card-section class="row items-center q-pb-none text-green-vito">
      <div class="text-h6">{{ $t("title.editProfile") }}</div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    <q-card-section>
      <q-form class="q-gutter-y-md">
        <q-input
          ref="name"
          square
          filled
          clearable
          v-model="name"
          :label="$t('label.name')"
          :hint="$t('hint.minchar', { chars: 3 })"
          lazy-rules
          :rules="[this.required, this.short]"
        >
          <template v-slot:prepend>
            <q-icon name="person" />
          </template>
        </q-input>
        <q-input
          ref="email"
          square
          filled
          clearable
          v-model="email"
          :hint="$t('hint.emailFormat')"
          type="email"
          :label="$t('label.email')"
          lazy-rules
          :rules="[this.required, this.isEmail]"
        >
          <template v-slot:prepend>
            <q-icon name="email" />
          </template>
        </q-input>

        <q-select
          v-model="locale"
          :options="localeOptions"
          :label="$t('label.language')"
          filled
          borderless
          square
          emit-value
          hint=""
          map-options
          ref="lang"
        >
          <template v-slot:prepend>
            <q-icon name="flag" />
          </template>
        </q-select>

        <q-input
          ref="password"
          autocomplete="new-password"
          square
          filled
          clearable
          v-model="password"
          :type="passwordFieldType"
          :label="$t('label.password')"
          lazy-rules
          :rules="[this.passwordValidator]"
          :hint="$t('hint.minchar', { chars: 6 })"
        >
          <template v-slot:prepend>
            <q-icon name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
              :name="visibilityIcon"
              @click="switchVisibility"
              class="cursor-pointer q-mt-xs"
            />
          </template>
        </q-input>
        <q-input
          ref="passwordVerification"
          square
          filled
          clearable
          v-model="passwordVerification"
          :type="passwordFieldType"
          :label="$t('label.repeatPassword')"
          lazy-rules
          :disable="!password"
          :rules="[this.passwordVerificationValidator, passwordValidator]"
          :hint="$t('hint.repeatPassword')"
        >
          <template v-slot:prepend>
            <q-icon name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
              :name="visibilityIcon"
              @click="switchVisibility"
              class="cursor-pointer q-mt-xs"
            />
          </template>
        </q-input>
      </q-form>
    </q-card-section>
    <q-card-section>
      <div v-if="errorMessage" class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
      <div v-else class="text-center text-grey-7 text-italic">
        {{ $t("hint.requireLogin") }}
      </div>
    </q-card-section>

    <q-card-actions class="q-px-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-blue-btz text-white"
        :label="$t('button.save')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import AuthApi from "../apis/Auth";
import UserApi from "../apis/User";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { locale } = useI18n({ useScope: "global" });

    return {
      locale,
      localeOptions: [
        { value: "de", label: "Deutsch" },
        { value: "fr", label: "Français" },
        { value: "en-US", label: "English" },
      ],
    };
  },
  data: () => {
    return {
      id: null,
      name: "",
      password: "",
      passwordVerification: "",
      email: "",
      passwordFieldType: "password",
      visibilityIcon: "visibility",
      errorMessage: null,
    };
  },
  async mounted() {
    try {
      const profileResponse = await AuthApi.profile();
      this.name = profileResponse.data.name;
      this.email = profileResponse.data.email;
      this.id = profileResponse.data.id;
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : error.message;
      this.errorMessage = message;
      this.$q.notify({
        type: "negative",
        message: message,
      });
    }
  },
  methods: {
    async handleSave() {
      this.$refs.email.validate();
      this.$refs.name.validate();
      this.$refs.password.validate();
      this.$refs.passwordVerification.validate();
      if (
        this.$refs.name.hasError ||
        this.$refs.email.hasError ||
        this.$refs.password.hasError ||
        this.$refs.passwordVerification.hasError
      ) {
        this.$emit("shake");
        // form has error
      } else {
        try {
          this.errorMessage = null;
          let payload = {
            name: this.name,
            email: this.email,
            language: this.locale,
          };
          if (
            this.password &&
            this.password.length &&
            this.password == this.passwordVerification
          ) {
            payload.password = this.password;
          }
          const saveResponse = await UserApi.save(this.id, payload);
          this.$q.notify({
            type: "positive",
            message: this.$t(saveResponse.data.message),
          });
          //console.log("locale", this.locale);
          localStorage.setItem("language", this.locale);
          //console.log(saveResponse.data);
          this.$emit("hide");
          this.$emit("logout");
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
          //console.error(message);
        }
      }
    },
    required(val) {
      return (val && val.length > 0) || this.$t("validation.required");
    },
    short(val) {
      return (
        (val && val.length > 2) || this.$t("validation.minchar", { chars: 3 })
      );
    },
    passwordValidator(val) {
      return val && val.length
        ? (val && val.length > 5) || this.$t("validation.minchar", { chars: 6 })
        : true;
    },
    passwordVerificationValidator(val) {
      return this.password && this.password.length
        ? val === this.password || this.$t("validation.repeatPassword")
        : true;
    },
    isEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || this.$t("validation.isEmail");
    },

    switchVisibility() {
      this.visibility = !this.visibility;
      this.passwordFieldType = this.visibility ? "text" : "password";
      this.visibilityIcon = this.visibility ? "visibility_off" : "visibility";
    },
  },
};
</script>
