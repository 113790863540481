import Api from "./Api";
export default {
  findAll() {
    return Api().get("/monitoring/script");
  },
  execute(id) {
    return Api().post(`/monitoring/script/${id}`);
  },
  findExecutions(scriptId) {
    return Api().get(`/monitoring/script/${scriptId}/executions`);
  }
};
