<template>
  <q-layout view="hHh Lpr lfr">
    <q-header elevated class="bg-blue-btz" id="top-banner">
      <q-toolbar>
        <router-link to="/">
          <q-img
            src="/img/logo.png"
            style="
              height: 120px;
              width: 230px;
              margin-left: -12px;
              margin-right: 12px;
            "
            alt="Butagaz stats"
          />
        </router-link>
        <q-btn
          flat
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title id="lg-tool-bar-title" class="text-uppercase">
          {{ $t($store.state.title) }}
        </q-toolbar-title>

        <!--q-input
          outlined
          dense
          v-model="search"
          bg-color="teal-1"
          class="q-mr-md gt-xs top-search-input text-body1"
          placeholder="Parc, client, commande"
          @keydown.enter="handleSearch"
        >
          <template v-slot:prepend>
            <q-icon v-if="search === ''" name="search" />
            <q-icon
              v-else
              name="clear"
              class="cursor-pointer"
              @click="search = ''"
            />
          </template>
        </q-input-->

        <q-avatar
          size="60px"
          color="red-vito"
          class="q-ml-auto q-mr-sm cursor-pointer"
        >
          {{ nameLetter }}
          <q-menu anchor="bottom right" self="top right" auto-close>
            <q-item clickable @click="editProfileOpen = true">
              <q-icon name="manage_accounts" size="sm" class="q-mr-sm" />
              <q-item-section>{{ $t("button.myProfile") }}</q-item-section>
            </q-item>
            <q-item clickable @click="logout">
              <q-icon name="logout" size="sm" class="q-mr-sm" />
              <q-item-section>{{ $t("button.logOut") }}</q-item-section>
            </q-item>
          </q-menu>
        </q-avatar>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      class="bg-grey-7"
      :width="230"
    >
      <q-img
        src="/img/logo.png"
        style="height: 120px; width: 230px"
        class="lt-md"
      />
      <!--q-input
        outlined
        dense
        square
        v-model="search"
        class="bg-grey-2 q-mt-md q-mx-md lt-sm top-search-input"
        placeholder="Parc, client, commande"
        @keydown.enter="
          leftDrawerOpen = false;
          handleSearch();
        "
      >
        <template v-slot:prepend>
          <q-icon v-if="search === ''" name="search" />
          <q-icon
            v-else
            name="clear"
            class="cursor-pointer"
            @click="search = ''"
          />
        </template>
      </q-input-->

      <q-list dark class="text-white q-mt-lg" style="overflow: hidden">
        <q-item clickable to="/" active-class="text-white">
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ $t("button.dashboard") }}</q-item-label>
          </q-item-section>
        </q-item>

        <q-expansion-item
          icon="miscellaneous_services"
          :label="$t('menu.reports')"
          default-opened
        >
          <q-item
            clickable
            to="/report/refill"
            active-class="text-white"
            :class="orderClass"
          >
            <q-item-section avatar>
              <q-icon name="apps" class="q-ml-md" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="q-ml-md">{{
                $t("menu.refill")
              }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            clickable
            to="/reports/changes"
            active-class="text-white"
            :class="storeClass"
          >
            <q-item-section avatar>
              <q-icon name="apps" class="q-ml-md" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="q-ml-md">{{
                $t("menu.changes")
              }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          icon="construction"
          :label="$t('menu.system')"
          :default-opened="systemOpened"
        >
          <q-item clickable to="/users" active-class="text-white">
            <q-item-section avatar>
              <q-icon name="manage_accounts" class="q-ml-md" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="q-ml-md">{{
                $t("menu.users")
              }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable to="/scripts" active-class="text-white">
            <q-item-section avatar>
              <q-icon name="tasks" class="q-ml-md" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="q-ml-md">{{
                $t("menu.scripts")
              }}</q-item-label>
            </q-item-section>
          </q-item>

        </q-expansion-item>
      </q-list>
    </q-drawer>

    <q-dialog v-model="editProfileOpen" ref="editProfileDialog">
      <profile
        v-on:logout="logout"
        v-on:hide="editProfileOpen = false"
        v-on:shake="$refs.editProfileDialog.shake()"
      />
    </q-dialog>

    <q-page-container>
      <q-scroll-area
        :thumb-style="thumbStyle"
        style="height: calc(100vh - 120px); width: 100%"
        class="bg-grey-1"
      >
        <router-view />
      </q-scroll-area>
    </q-page-container>
  </q-layout>
</template>

<script>
import Profile from "../components/Profile.vue";
import AuthApi from "../apis/Auth";
import { useI18n } from "vue-i18n";

export default {
  name: "LayoutDefault",
  components: {
    Profile,
  },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      editProfileOpen: false,
      leftDrawerOpen: false,
      search: "",
      nameLetter: "?",

      thumbStyle: {
        right: "0px",
        borderRadius: "5px",
        backgroundColor: "rgb(0, 96, 86)",
        width: "10px",
        opacity: 0.55,
        previousRoutePath: null,
      },
      activeItemClass: "q-router-link--exact-active",
    };
  },
  async mounted() {
    try {
      const profileResponse = await AuthApi.profile();
      this.$store.commit("setUser", profileResponse.data);
      this.locale = profileResponse.data.language;
      this.nameLetter = this.$store.getters.userName.substring(0, 1);
    } catch (error) {
      if (error.response && error.response.data.statusCode == 401) {
        console.error(error.response.data.message);
        this.logout();
      } else {
        console.error(error);
      }
    }
  },
  methods: {
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.previousRoutePath = from.path;
      });
    },
    handleSearch() {
      console.log("searching...");
    },
    logout() {
      localStorage.removeItem("token");
      this.$store.commit("setToken", null);
      this.$store.commit("setUser", null);
      this.$router.push(
        "/login" + this.previousRoutePath
          ? "?redirect=" + this.previousRoutePath
          : ""
      );
    },
  },
  computed: {
    contractClass() {
      return this.$route.fullPath.slice(0, 10) == "/contract/"
        ? this.activeItemClass
        : "";
    },
    storeClass() {
      return this.$route.fullPath.slice(0, 7) == "/store/"
        ? this.activeItemClass
        : "";
    },
    orderClass() {
      return this.$route.fullPath.slice(0, 7) == "/order/"
        ? this.activeItemClass
        : "";
    },
    settingsClass() {
      return this.$route.fullPath.slice(0, 7) == "/admin/"
        ? this.activeItemClass
        : "";
    },
    logsClass() {
      return this.$route.fullPath.slice(0, 6) == "/logs/"
        ? this.activeItemClass
        : "";
    },
    visitClass() {
      return this.$route.fullPath.slice(0, 7) == "/visit/"
        ? this.activeItemClass
        : "";
    },
    systemOpened() {
      return ["/user", "/inte", "/logs", "/docs", "/admi", "/visi"].includes(
        this.$route.fullPath.slice(0, 5)
      );
    },
  },
};
</script>

<style scoped>
.q-drawer .q-router-link--exact-active::before {
  content: "";
  position: absolute;
  left: 230px;
  display: block;
  border-top: 0px solid transparent;
  border-right: 0px solid transparent;
  border-left: 10px solid #fafafa;
  border-bottom: 10px solid #fafafa;
  width: 10px;
  height: 10px;
  transform: translate(-5px, 12px) rotate(45deg);
}
.q-drawer__content {
  overflow-x: hidden !important;
}
</style>
