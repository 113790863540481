<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.scripts") }}
      </div>
      <div class="q-gutter-x-md row">
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 150px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="scripts"
        :columns="columnsI18n"
        row-key="id"
        :pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        class="sticky-header-table"
      >
      <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-sm">
              <q-btn
                icon="send"
                square
                round
                dense
                @click="handleExecuteButton(props.row)"
              >
                <q-tooltip class="bg-blue-btz text-no-wrap">
                  {{ $t("tooltip.execute") }}
                </q-tooltip>
              </q-btn>
              <q-btn
                icon="edit"
                v-if="false"
                square
                round
                dense
                @click="editScript(props.row)"
              >
                <q-tooltip class="bg-blue-btz text-no-wrap">
                  {{ $t("tooltip.editScript") }}
                </q-tooltip>
              </q-btn>
              <q-btn
                icon="visibility"
                square
                round
                dense
                @click="viewScript(props.key)"
              >
                <q-tooltip class="bg-blue-btz text-no-wrap">
                  {{ $t("tooltip.viewScript") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>

      </q-table>
    </q-card-section>
  </q-card>
</template>
<script>
import ScriptApi from "../apis/Script";
import { useI18n } from "vue-i18n";

export default {
  name: "ScriptsPage",

  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      action: "list",
      showNew: false,
      showCard: false,
      filter: "",
      loading: false,
      scripts: [],
      perPage: 50,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
    };
  },
  methods: {
    async getScripts() {
      this.loading = true;
      try {
        const scriptsReponse = await ScriptApi.findAll();

        this.scripts = scriptsReponse.data;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      this.showNew = false;
      this.showCard = false;
      await this.getScripts();
    },
    async handleExecuteButton(script) {
      console.log(`Execute ${script.script}`);
      await ScriptApi.execute(script.id);
    },
    async viewScript(scriptId) {
      this.$router.push("/executions/" + scriptId);
    }
  },

  async mounted() {
    this.$store.state.title = "button.scripts";
    await this.getScripts();
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "script",
          label: this.$t("label.name"),
          field: "script",
          align: "left",
          sortable: true,
        },
        {
          name: "cron",
          label: this.$t("label.cron"),
          field: "cron",
          align: "left",
          sortable: true,
        },
        {
          name: "last execution success",
          label: this.$t("label.lastSuccessExecutionDate"),
          field: "lastSuccessExecutionDate",
          align: "left",
          sortable: true,
        },
        {
          name: "last execution",
          label: this.$t("label.lastExecutionDate"),
          field: "lastExecutionDate",
          align: "left",
          sortable: true,
        },
        {
          name: "last execution result",
          label: this.$t("label.lastExecutionResult"),
          field: "lastExecutionResult",
          align: "left",
          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
